body {
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: 'SF Pro Display Regular';
    height: 100%;
}

html {
    height: 100%;
}

#root {
    height: 100%;
    position: relative;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

@font-face {
    font-family: 'Inter';
    src: url('./assets/fonts/Inter-Thin.otf');
}

@font-face {
    font-family: 'Inter Extra';
    src: url('./assets/fonts/Inter-ExtraLight.otf');
}

@font-face {
    font-family: 'SF Pro Display';
    src: url('./assets/fonts/SF-Pro-Display.otf');
}

@font-face {
    font-family: 'SF Pro Display Regular';
    src: url('./assets/fonts/SF-Pro-Display-Regular.otf');
}